import './App.scss';

import LandingPage from './pages/LandingPage';

function App() {
  return (
          <LandingPage></LandingPage>
  );
}

export default App;
